@font-face {
  font-family: Avenir;
  src: url(../font/avenir/AvenirBlack.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Avenir;
  src: url(../font/avenir/AvenirBook.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Avenir;
  src: url(../font/avenir/AvenirHeavy.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Avenir;
  src: url(../font/avenir/AvenirLight.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Avenir;
  src: url(../font/avenir/AvenirRegular.ttf);
  font-weight: 400;
}
