.user {
  &-detail {
    &-name {
      font-size: 60px;
      font-weight: 800;
    }

    &-company {
      font-size: 30px;
    }
  }

  &-page {
    &-header {
      margin-bottom: 45px;
      margin-top: 20px;
    }
  }

  &-contact-details {
    margin-top: 30px;
  }
}

.detail-contact {
  font-size: 30px;

  &:first-child {
    margin-right: 75px;
  }

  span {
    margin-right: 25px;
  }
}

.device-count {
  font-size: 29px;
  font-weight: 800;
}
