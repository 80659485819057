.react-select__control {
    border-color: #ced4da;
    border-radius: 0.375rem;
    padding: 0.375rem 0.75rem;
    background-color: #fff;
    box-shadow: none;
}

.react-select__control:hover {
    border-color: #86b7fe;
}

.react-select__menu {
    border-radius: 0.375rem;
    border: 1px solid #ced4da;
    box-shadow: 0 0.15rem 0.5rem rgba(0, 0, 0, 0.1);
    margin-top: 0;
}

.react-select__option {
    padding: 0.375rem 0.75rem;
}

.react-select__option--is-focused {
    background-color: #e9ecef !important; /* Light grey hover */
}

.react-select__option--is-selected {
    background-color: #0d6efd !important; /* Bootstrap blue */
    color: #fff !important; /* White text for selected item */
}

.react-select__single-value {
    color: #495057; /* Bootstrap text color */
}