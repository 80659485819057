.admin-detail {
  display: grid;
  grid-template-columns: 65% 32%;
  column-gap: 50px;

  @media (max-width: 1440px) {
    grid-template-columns: 1fr;
  }
}

.add-btn {
  padding: 16px 12px;
  border-radius: 20px;
  background-color: $white;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
  width: 130px;
  margin: 0 10px;
  height: 133px;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    width: 105px;
    height: 115px;
    border-radius: 12px;

    svg {
      width: 40px;
      height: 42px;
    }
  }

  @media (max-width: 560px) {
    width: 85px;
    height: 90px;
    border-radius: 12px;
    margin: 0 5px;
  }

  &:hover {
    opacity: 1;

    .btn {
      &-icon {
        path {
          fill: $dark;
          fill-opacity: 1;
        }

        rect {
          stroke: $dark;
          stroke-opacity: 1;
        }
      }

      &-text {
        color: $dark;
      }
    }
  }
}

.btn {
  &-text {
    font-size: 15px;
    font-weight: 700;
    color: $primary;
    transition: all 0.3s ease;

    @media (max-width: 768px) {
      font-size: 10px;
    }

    @media (max-width: 560px) {
      font-size: 8px;
    }
  }

  &-icon {
    transition: all 0.3s ease;

    svg {
      @media (max-width: 560px) {
        width: 30px;
        height: 33px;
      }
    }
  }
}

.add-popup-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    justify-content: flex-start;
  }

  // @media (max-width: 560px) {
  //   display: grid;
  //   grid-template-columns: repeat(2, 1fr);
  //   row-gap: 25px;
  // }
}

// .customer-list-wrap {
//   .list-card {
//     display: grid;
//     grid-template-columns: 25% 35% 40%;
//   }
// }

.other-info {
  justify-content: flex-end;

  svg {
    @media (max-width: 560px) {
      svg {
        width: 20px;
        height: 22px;
      }
    }
  }
}

.list {
  &-card {
    padding: 15px 15px 20px 25px;
    border-radius: 20px;
    align-items: center;
    background-color: $white;
    min-height: 87px;
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: 25% 35% 40%;

    @media (max-width: 560px) {
      padding: 12px 0 15px 12px;
      border-radius: 12px;
      grid-template-columns: 25% 30% 45%;
    }

    .flow {
      &-data {
        font-size: 23px;
        font-weight: 800;
        color: $primary;
        text-align: center;
        margin-bottom: 0;

        @media (max-width: 560px) {
          font-size: 18px;
        }
      }

      &-name {
        font-size: 12px;
        text-align: center;

        @media (max-width: 560px) {
          font-size: 9px;
        }
      }
    }
  }

  &-name {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 5px;
    color: $dark;

    @media (max-width: 560px) {
      font-size: 15px;
    }
  }
}

.company-name {
  font-size: 12px;

  @media (max-width: 560px) {
    font-size: 9px;
  }
}

.unit {
  font-size: 14px;
  text-align: center;

  @media (max-width: 560px) {
    font-size: 8px;
  }
}

.action-btn {
  margin-left: 15px;

  @media (max-width: 560px) {
    margin-left: 5px;
  }
}

.listing-wrap {
  margin-top: 80px;

  @media (max-width: 1024px) {
    margin-top: 30px;
  }
}

.date {
  &-info {
    font-size: 12px;
    font-weight: 600;

    @media (max-width: 560px) {
      font-weight: 500;
    }

    &:first-child {
      margin-bottom: 10px;

      @media (max-width: 560px) {
        margin-bottom: 5px;
      }
    }

    span {
      color: $primary;
    }
  }
}

.empty-msg {
  font-size: 15px;
  color: $primary;
  text-align: center;

  @media (max-width: 560px) {
    font-size: 8px;
  }
}

.status-card {
  &:nth-child(2) {
    margin-bottom: 70px;
  }
}

.form-btn {
  justify-content: center;
}

.delete-device {
  svg {
    @media (max-width: 1440px) {
      height: 75px;
      width: 75px;
    }

    @media (max-width: 1280px) {
      height: 45px;
      width: 45px;
    }
  }
}

.admin-device-detail {
  .user-inner-detail {
    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 25px;
    }
  }
}

.admin-layout {
  .card-actions {
    @media (max-width: 560px) {
      position: absolute;
      right: 30px;
    }
  }

  .tab-header {
    @media (max-width: 1440px) {
      margin-left: -160px;
    }

    @media (max-width: 768px) {
      margin-left: 15px;
      max-width: 80vw;
      overflow: auto;
    }
  }

  .listing-detail-wrap {
    .empty-text {
      display: grid;
      grid-column-start: 2;
      grid-column-end: 5;
    }

    @media (max-width: 1440px) {
      grid-template-columns: 20% 15% 25% 40%;
    }

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
      row-gap: 25px;

      .user-dates {
        margin-left: 0;
        grid-column-start: 1;
        grid-column-end: 4;
      }

      .empty-text {
        display: grid;
        grid-column-start: 2;
        grid-column-end: 4;
      }
    }

    @media (max-width: 560px) {
      grid-template-columns: repeat(4, 1fr);

      .about-info-wrap {
        display: grid;
        grid-column-start: 1;
        grid-column-end: 3;
      }

      .flow-info {
        display: grid;
        grid-column-start: 1;
        grid-column-end: 3;
      }

      .total-flow-info {
        display: grid;
        grid-column-start: 3;
        grid-column-end: 5;
      }

      .user-dates {
        grid-column-start: 1;
        grid-column-end: 5;
      }

      .empty-text {
        font-size: 15px;
        grid-column-start: 1;
        grid-column-end: 5;
      }
    }
  }

  .detail-listing-wrap {
    @media (max-width: 1024px) {
      height: auto;
      align-items: flex-start;
    }
  }
}

.btn-action {
  svg {
    @media (max-width: 1440px) {
      width: 40px;
    }

    @media (max-width: 560px) {
      width: 20px;
      height: 20px;
    }
  }
}

.search-input-container {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  width: 100%;
  padding: 0;
  
  &.visible {
    max-height: 50px;
    opacity: 1;
    margin-bottom: 1rem !important;
  }
  
  input {
    width: 100%;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid rgba($color: $dark, $alpha: 0.1);
    
    &:focus {
      border-color: $primary;
      box-shadow: none;
    }
  }
}

.title-container {
  margin-bottom: 1rem;
  
  .search-toggle-btn {
    background: none;
    border: none;
    padding: 5px;
    color: $primary;
    cursor: pointer;
    transition: all 0.3s ease;
    
    &:hover {
      color: darken($primary, 10%);
    }
  }
}
