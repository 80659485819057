.tab {
  &-header {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    @media (max-width: 1440px) {
      margin-top: -35px;
    }

    .btn-tab {
      background-color: rgba($color: $white, $alpha: 0.6);
      color: $primary;
      border: none;
      padding: 7px 45px;
      text-align: center;
      border-radius: 15px;
      cursor: pointer;
      font-size: 22px;
      font-weight: 600;
      outline: none;
      transition: background-color 0.3s ease;
      margin-inline: 25px;

      @media (max-width: 1440px) {
        border-radius: 10px;
        font-size: 19px;
        margin-inline: 20px;
      }

      @media (max-width: 1024px) {
        margin-inline: 10px;
        font-size: 17px;
        padding: 5px 25px;
      }

      @media (max-width: 560px) {
        border-radius: 5px;
        font-size: 14px;
        padding: 5px 20px;
      }

      &:hover {
        background-color: $white;
      }

      &.active {
        background-color: $white;
        color: $dark;
      }
    }
  }
}

.seperator {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 30px;
    background: $dark;
    top: 9px;
    left: 0;

    @media (max-width: 992px) {
      top: 2px;
    }

    @media (max-width: 560px) {
      height: 15px;
    }
  }
}

.recharts {
  &-responsive-container {
    background-color: $white;
    border-radius: 30px;
    overflow: hidden;
    padding-bottom: 20px;
    margin-top: 15px;

    @media (max-width: 1200px) {
      border-radius: 20px;
    }
  }
}

.date-input::-webkit-calendar-picker-indicator {
  background-image: none;
  position: absolute;
  left: 10px;
  width: 100%;
  height: 100%;
}

.time-input {
  position: relative;

  &::-webkit-calendar-picker-indicator {
    background-image: none;
    position: absolute;
    left: 10px;
    width: 100%;
    height: 100%;
  }
}

.date-input {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    height: 25px;
    width: 2px;
    background: #09c4ff;
    right: 5px;
    top: 8px;

    @media (max-width: 1024px) {
      height: 18px;
      right: 10px;
    }
  }

  &-group {
    border-radius: 10px;
    background: $white;
    overflow: hidden;
    width: fit-content;
    padding: 0;
    display: flex;
    margin-right: 30px;

    @media (max-width: 1024px) {
      margin-right: 0px;
      border-radius: 8px;
    }
  }

  &-wrap {
    margin-right: 130px;

    @media (max-width: 1200px) {
      margin-right: 40px;
    }
  }
}

.calendar-label {
  padding: 10px 8px;
  background: $primary;
}

.date-input,
.time-input {
  border: none;
  border-radius: 0;
  padding: 0;
  background: 0;
  padding: 10px 0 10px 15px;
  width: fit-content;

  @media (max-width: 1024px) {
    font-size: 14px;
  }

  &:focus {
    outline: none;
  }
}

.time-input {
  @media (max-width: 1024px) {
    padding-left: 5px;
    padding-right: 10px;
  }
}

.date-range {
  margin-block: 30px;
  display: flex;

  @media (max-width: 1680px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
  }

  @media (max-width: 820px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
}

.input-label {
  font-size: 14px;
}

.white-btn {
  padding: 8px 47px;
  background: $white;
  color: $primary;
  font-size: 23px;
  font-weight: 500;
  transition: all 0.3s ease;
  border-radius: 10px;
  max-width: 200px;

  @media (max-width: 1024px) {
    font-size: 20px;
    border-radius: 8px;
    padding: 8px 40px;
  }

  @media (max-width: 820px) {
    font-size: 16px;
  }

  &:hover {
    background: $primary;
    color: $white;
  }
}

.calendar-label {
  font-size: 24px;
  line-height: 28px;

  img {
    width: 25px;
  }
}

.fluid-wrap.radio-card {
  padding: 25px 40px;
  height: auto;

  @media (max-width: 560px) {
    padding: 0 0 20px 25px;
  }

  .current-flow-info {
    margin-left: 108px;

    @media (max-width: 1024px) {
      margin-left: 50px;
    }

    @media (max-width: 560px) {
      margin-left: 0px;
    }
  }
}

.radio-btn {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid $primary;
  margin-right: 45px;

  @media (max-width: 820px) {
    width: 20px;
    height: 20px;
    margin-right: 30px;
  }

  @media (max-width: 560px) {
    margin-right: 20px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: none;

    @media (max-width: 820px) {
      width: 12px;
      height: 12px;
    }
  }
}

.chart-radio-btn {
  &:checked ~ .radio-btn-wrap {
    .radio-btn {
      &::after {
        background-color: $primary;
      }
    }
  }
}

.export-btn {
  padding: 10px 15px;
  font-size: 21px;
  font-weight: 800;
  background: $white;
  border-radius: 14px;

  @media (max-width: 1440px) {
    border-radius: 10px;
    font-size: 18px;
  }

  @media (max-width: 560px) {
    font-size: 14px;
  }

  button {
    margin-left: 20px;

    @media (max-width: 560px) {
      margin-left: 10px;
    }

    img {
      @media (max-width: 560px) {
        height: 20px;
      }
    }
  }
}

.table-filter-header {
  .dropdown__button,
  .dropdown__menu li {
    font-size: 18px;

    @media (max-width: 820px) {
      font-size: 15px;
    }
  }

  .date-input-wrap {
    margin-right: 30px;
  }

  .calendar-label {
    padding: 6px 8px;
  }

  .date-input,
  .time-input {
    padding: 7px 0px 7px 10px;
  }
}

.table-content {
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 10px;
    overflow: hidden;
    background-color: $primary;
    padding: 11px;
    position: relative;

    @media (max-width: 1440px) {
      font-size: 20px;
    }

    @media (max-width: 1280px) {
      font-size: 18px;
    }

    @media (max-width: 1200px) {
      font-size: 16px;
    }

    @media (max-width: 820px) {
      font-size: 12px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 0.75px;
      height: 35px;
      background: $dark;
      right: 0;

      @media (max-width: 1440px) {
        right: 1px;
        height: 30px;
      }

      @media (max-width: 1200px) {
        height: 25px;
      }

      @media (max-width: 820px) {
        height: 18px;
      }
    }

    &:first-child {
      border-bottom-left-radius: 12px;
      border-top-left-radius: 12px;

      @media (max-width: 1024px) {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
    }

    &:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;

      @media (max-width: 1024px) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &::after {
        content: none;
      }
    }
  }

  tr {
    &:first-child {
      td {
        padding-top: 22px;

        @media (max-width: 820px) {
          padding-top: 12px;
        }

        &:first-child {
          border-top-left-radius: 12px;

          @media (max-width: 820px) {
            border-top-left-radius: 8px;
          }
        }
        &:last-child {
          border-top-right-radius: 12px;

          @media (max-width: 820px) {
            border-top-right-radius: 8px;
          }
        }
      }
    }

    &:last-child {
      td {
        padding-bottom: 22px;

        @media (max-width: 820px) {
          padding-bottom: 12px;
        }
        &:first-child {
          border-bottom-left-radius: 12px;

          @media (max-width: 820px) {
            border-bottom-left-radius: 8px;
          }
        }
        &:last-child {
          border-bottom-right-radius: 12px;

          @media (max-width: 820px) {
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }

  th,
  td {
    text-align: center;
  }

  tbody {
    background-color: $white;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    top: 10px;
  }

  tfoot {
    background-color: $white;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    top: 20px;
    display: table-caption;
    width: 100%;
    padding: 10px 25px;

    @media (max-width: 560px) {
      padding: 7px 14px;
      top: 15px;
      border-radius: 5px;
    }

    .table-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .pagination-button-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow-button {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background: $primary;
    border-radius: 8px;
    transition: all 0.3s ease;
    color: $white;
    margin: 0 3px;
    border: 1px solid $primary;

    @media (max-width: 1440px) {
      width: 25px;
      height: 25px;
      font-size: 13px;
      border-radius: 5px;
    }

    @media (max-width: 560px) {
      width: 15px;
      height: 15px;
      font-size: 9px;
      border-radius: 3px;
    }

    &:hover {
      background: none;
      color: $primary;
    }

    &:disabled {
      background: $gray-400;
      color: $gray-600;
      cursor: not-allowed;
      border: none;
    }
  }

  .page-button {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid $primary;
    border-radius: 8px;
    transition: all 0.3s ease;
    color: $primary;
    font-weight: 800;
    margin: 0 3px;

    @media (max-width: 1440px) {
      width: 25px;
      height: 25px;
      font-size: 14px;
      border-radius: 5px;
    }

    @media (max-width: 560px) {
      width: 15px;
      height: 15px;
      font-size: 10px;
      border-radius: 3px;
      font-weight: 400;
      margin: 0;
    }

    &.active {
      background: $primary;
      color: $white;
    }

    &:hover {
      background: $primary;
      color: $white;
    }
  }

  td {
    padding: 11px;
    position: relative;

    @media (max-width: 1440px) {
      font-size: 14px;
    }

    @media (max-width: 820px) {
      font-size: 9px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 0.75px;
      height: 35px;
      background: $dark;
      right: 0;

      @media (max-width: 1440px) {
        height: 27px;
      }

      @media (max-width: 820px) {
        height: 15px;
      }
    }

    &:last-child {
      &::after {
        content: none;
      }
    }

    span {
      &.date {
        padding-right: 33px;
        position: relative;

        @media (max-width: 820px) {
          padding-right: 10px;
        }

        &.from,
        &.to {
          padding: 0;

          &::after {
            content: none;
          }
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          width: 1px;
          height: 25px;
          background-color: $primary;

          @media (max-width: 820px) {
            height: 15px;
          }
        }
      }

      &.time {
        margin-left: 33px;

        @media (max-width: 820px) {
          margin-left: 10px;
        }
      }
    }
  }
}

span {
  &.dateNew {
    padding-right: 33px;
    position: relative;

    @media (max-width: 820px) {
      padding-right: 10px;
    }

    &.from,
    &.to {
      padding: 0;

      &::after {
        content: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      width: 1px;
      height: 25px;
      background-color: $primary;

      @media (max-width: 820px) {
        height: 15px;
      }
    }
  }

  &.time {
    margin-left: 33px;

    @media (max-width: 820px) {
      margin-left: 10px;
    }
  }
}