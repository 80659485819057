.modal {
  &-content {
    background: linear-gradient(180deg, $white 0%, $primary 100%);
    border-radius: 24px;
    padding: 60px 80px;

    @media (max-width: 1440px) {
      padding: 60px 50px;
    }

    @media (max-width: 560px) {
      padding: 40px 30px;
      border-radius: 20px;
    }
  }

  &-body {
    border-radius: 24px;
    border: 2px solid #000;
    padding: 52px;
    position: relative;

    @media (max-width: 560px) {
      padding: 35px;
      border-radius: 18px;
    }
  }

  &-title {
    padding: 9px 50px;
    color: $primary;
    font-size: 28px;
    position: absolute;
    top: -33px;
    left: 36%;
    text-align: center;
    background: linear-gradient(180deg, #f4fcff, #c1f0ff);

    @media (min-width: 2000px) {
      left: 40%;
    }

    @media (max-width: 1440px) {
      font-size: 24px;
      left: 38%;
    }

    @media (max-width: 1024px) {
      font-size: 20px;
      top: -24px;
    }

    @media (max-width: 820px) {
      left: 33%;
    }

    @media (max-width: 560px) {
      font-size: 15px;
      left: 21%;
      top: -21px;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.form-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 75px;
  row-gap: 35px;

  @media (max-width: 820px) {
    column-gap: 25px;
    row-gap: 25px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.modal-75w {
  width: 75%;
  max-width: none !important;

  @media (max-width: 1366px) {
    width: 85%;
  }

  @media (max-width: 1240px) {
    width: 92%;
  }

  @media (max-width: 560px) {
    width: 96%;
  }
}

// Remove the .custom-dropdown styles entirely
// The form-select class from Bootstrap will handle the styling