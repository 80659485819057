.login {
  &-screen {
    padding-top: 100px;
    padding-bottom: 35px;
    height: calc(100vh - 85px);

    @media (max-width: 1024px) {
      height: calc(100vh - 180px);
    }

    @media (max-width: 820px) {
      height: auto;
    }

    @media (max-width: 560px) {
      padding-top: 50px;
    }
  }

  &-body {
    border: 1px solid $dark;
    border-radius: 15px;
    overflow: hidden;
    height: 100%;

    @media (max-width: 820px) {
      padding-bottom: 50px;
    }
  }

  &-bg {
    overflow: hidden;

    img {
      @media (max-width: 820px) {
        width: 100%;
      }
    }

    @media (max-width: 820px) {
      height: 400px;
      margin-bottom: 50px;
    }

    @media (max-width: 560px) {
      height: 240px;
    }
  }
}

.logo-text {
  margin-bottom: 70px;

  @media (max-width: 1880px) {
    margin-bottom: 20px;
    width: 200px;
  }
}
