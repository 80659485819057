.device-detail-header {
  padding: 35px 65px;
  background: $white;
  border-radius: 30px;
  margin-bottom: 65px;
  position: relative;

  @media (max-width: 1280px) {
    padding: 25px 45px;
    border-radius: 20px;
  }
}

.user-inner-detail {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;

  @media (max-width: 820px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  }
}

.device-name {
  font-size: 45px;
  font-weight: 800;

  @media (max-width: 1680px) {
    font-size: 38px;
  }

  @media (max-width: 1440px) {
    font-size: 35px;
  }

  @media (max-width: 1280px) {
    font-size: 30px;
  }

  @media (max-width: 1024px) {
    font-size: 26px;
  }

  @media (max-width: 560px) {
    font-size: 14px;
    font-weight: 400;
  }
}

.alias-name,
.assignee-info {
  font-size: 24px;

  @media (max-width: 1680px) {
    font-size: 20px;
  }

  @media (max-width: 1440px) {
    font-size: 18px;
  }

  @media (max-width: 1280px) {
    font-size: 15px;
  }

  @media (max-width: 1024px) {
    font-size: 13px;
  }

  @media (max-width: 560px) {
    font-size: 10px;
  }
}

.assignee-detail {
  font-size: 35px;
  font-weight: 800;

  @media (max-width: 1680px) {
    font-size: 30px;
  }

  @media (max-width: 1440px) {
    font-size: 27px;
  }

  @media (max-width: 1280px) {
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    font-size: 22px;
  }

  @media (max-width: 560px) {
    font-size: 14px;
    font-weight: 400;
  }
}

.flow-detail {
  font-size: 60px;
  font-weight: 800;
  color: $primary;

  @media (max-width: 1680px) {
    font-size: 52px;
  }

  @media (max-width: 1440px) {
    font-size: 48px;
  }

  @media (max-width: 1280px) {
    font-size: 40px;
  }

  @media (max-width: 1024px) {
    font-size: 32px;
  }

  @media (max-width: 560px) {
    font-size: 18px;
  }

  span {
    margin-left: 15px;
    font-size: 24px;
    color: $dark;
    font-weight: 400;

    @media (max-width: 1680px) {
      font-size: 20px;
      margin-left: 0;
    }

    @media (max-width: 1280px) {
      font-size: 15px;
    }

    @media (max-width: 1024px) {
      font-size: 13px;
    }

    @media (max-width: 560px) {
      font-size: 10px;
    }
  }
}

.flow-type {
  font-size: 30px;

  @media (max-width: 1800px) {
    font-size: 26px;
  }

  @media (max-width: 1680px) {
    font-size: 23px;
  }

  @media (max-width: 1440px) {
    font-size: 20px;
  }

  @media (max-width: 1280px) {
    font-size: 17px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 560px) {
    font-size: 13px;
  }
}

.me-180 {
  margin-right: 180px;

  @media (max-width: 1440px) {
    margin-right: 85px;
  }

  @media (max-width: 560px) {
    margin-right: 0;
  }
}

.flow-card-data {
  @media (max-width: 560px) {
    justify-content: space-between;
    width: 100%;
  }
}

.listing-with-tab {
  .tab-header {
    @media (max-width: 1024px) {
      margin-top: -35px;
      margin-left: -125px;
      max-width: calc(100vw - 170px);
      overflow: auto;
      position: relative;
    }

    @media (max-width: 768px) {
      margin-top: 0px;
      margin-left: 0px;
    }

    @media (max-width: 560px) {
      max-width: calc(100vw - 100px);
    }
  }
}

.flow-detail-wrap {
  .device-status {
    @media (max-width: 560px) {
      position: absolute;
      top: 0px;
      right: 20px;
    }
    svg {
      @media (max-width: 1680px) {
        width: 80px;
      }

      @media (max-width: 1440px) {
        width: 60px;
      }

      @media (max-width: 1280px) {
        width: 30px;
      }

      @media (max-width: 560px) {
        width: 22px;
        height: 45px;
      }
    }
  }
}

.user-device-list-wrap {
  .detail-listing-wrap {
    display: grid;
    grid-template-columns: 25% 45% 20%;
    height: auto;

    @media (max-width: 1440px) {
      grid-template-columns: 25% 53% 20%;
    }

    @media (max-width: 820px) {
      grid-template-columns: auto 1fr;
    }
  }

  @media (max-width: 560px) {
    .btn-action svg {
      width: 40px;
      height: 40px;
    }
  }
}
