.sidebar {
  &-wrap {
    position: sticky;
    top: 14%;
    left: 84px;
    flex-direction: column;
    display: flex;
    z-index: 0;

    @media (max-width: 992px) {
      left: 65px;
    }

    @media (max-width: 768px) {
      left: 0;
      position: fixed;
      top: auto;
      bottom: 0;
      flex-direction: row;
      justify-content: center;
      background-color: $white;
      width: 100%;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      z-index: 9;
    }

    &.user {
      .sidebar-icon-wrap {
        &.active {
          svg {
            fill: $black;

            &.dealer-icon {
              fill: none;
            }

            .black-rect {
              stroke: $dark;
              stroke-opacity: 1;
            }

            .black-path {
              fill: $dark;
              fill-opacity: 1;
            }

            path {
              fill: $black;
            }
          }
        }
      }
    }
  }

  &-icon-wrap {
    width: 125px;
    height: 125px;
    border-radius: 20px;
    background: rgba($color: $white, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block: 30px;
    transition: all 0.3s ease;

    svg {
      @media (max-width: 820px) {
        height: 40px;
      }

      @media (max-width: 560px) {
        height: 30px;
      }
    }

    @media (max-width: 1800px) {
      width: 115px;
      height: 115px;
      margin-block: 25px;
      border-radius: 15px;
    }

    @media (max-width: 1024px) {
      width: 100px;
      height: 100px;
      margin-block: 15px;
    }

    @media (max-width: 820px) {
      width: 75px;
      height: 75px;
      margin-block: 10px;
    }

    @media (max-width: 560px) {
      width: 50px;
      height: 40px;
      margin-block: 10px;
      margin-inline: 10px;
    }

    &:hover {
      background: rgba($color: $white, $alpha: 1);
    }

    &.active {
      background: rgba($color: $white, $alpha: 1);
    }
  }
}
