.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;

    &-left,
    &-right {
        width: 50%;
        height: 100%;
        background: $white;
        position: absolute;
        z-index: 1;
        top: 0;
    }

    &-left {
        left: 0;
        transition: all .3s ease;
    }

    &-right {
        right: 0;
        transition: all .3s ease;
    }

    &-left {
        animation-duration: 1s;
        animation-delay: 2s;
        animation-name: fade-left;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        animation-direction: linear;
    }

    &-right {
        animation-duration: 1s;
        animation-delay: 2s;
        animation-name: fade-right;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        animation-direction: linear;
    }

    &-close {
        display: none;
    }

    &-img {
        &-wrap {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
        }
    }
}

.loader-img {
    width: 80px;
    animation: fade 1s ease-in-out infinite;
}

@keyframes fade {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

@keyframes fade-left {
    from {
        left: 0;
    }

    to {
        left: -50%;
    }
}

@keyframes fade-right {
    from {
        right: 0;
    }

    to {
        right: -50%;
    }
}