.add-new {
  padding: 8px 17px;
  font-size: 21px;
  font-weight: 700;
  background-color: $white;
  margin-right: 50px;
  border-radius: 11px;

  @media (max-width: 1440px) {
    font-size: 18px;
    margin-right: 35px;
  }

  span {
    margin-left: 15px;
  }
}

.device-head {
  margin-top: 50px;

  @media (max-width: 560px) {
    flex-direction: column;
  }
}

.user-contact {
  @media (max-width: 1024px) {
    margin-left: 0;
    display: grid;
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

.user-links {
  @media (max-width: 560px) {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 4;
  }
}
