.dropdown {
  position: relative;

  svg {
    @media (max-width: 560px) {
      width: 18px;
    }
  }
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eee;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.dropdown-header:hover {
  background-color: #ddd;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0 0 5px 5px;
  z-index: 1;
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #ddd;
}
