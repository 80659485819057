@import url("https://fonts.googleapis.com/css2?family=Actor&display=swap");

// global
* {
  @include spacing-0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding-left: 85px;
  padding-right: 85px;
  padding-top: 0;
  padding-bottom: 50px;
  font-size: 18px;
  overflow-x: hidden;
  min-height: 100vh;
  width: 100vw;
  background: $white;
  background: linear-gradient(180deg, $white 0%, $primary 100%);
  background-size: cover; /* Ensure the background covers the entire element */
  background-position: center; /* Center the background image */
  background-attachment: fixed;
  color: $dark;
  position: relative;
  font-family: Avenir;
  font-weight: 400;
  caret: none;

  @media (max-width: 992px) {
    padding-left: 65px;
    padding-right: 65px;
    padding-bottom: 130px;
  }

  @media (max-width: 560px) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

a,
p {
  @include spacing-0;
}

a {
  text-decoration: none;
}

ul {
  padding-left: 0;
}

@for $i from 1 through 6 {
  h#{i} {
    @include spacing-0;
  }
}

// global

.main-content {
  padding-top: 154px;
  display: grid;
  grid-template-columns: 9.5% 87%;
  gap: 44px;
  position: relative;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 560px) {
    padding-top: 100px;
  }
}

button {
  border: none;
  box-shadow: none;
  outline: none;
  background: none;
}

.form {
  &-select {
    padding: 10px 25px 10px 10px;
    font-size: 22px;
  }

  &-label {
    font-size: 32px;
    color: $secondary;
    font-weight: 300;
    margin-bottom: 0;

    @media (max-width: 1880px) {
      font-size: 24px;
    }

    @media (max-width: 1024px) {
      font-size: 20px;
    }

    @media (max-width: 560px) {
      font-size: 16px;
    }
  }

  &-control {
    border-radius: 10px;
    border: 0.5px solid rgba($color: $black, $alpha: 0.3);
    padding: 10px;
    background: $white;
    color: $black;
    font-size: 22px;
    width: 475px;
    font-weight: 300;

    @media (max-width: 1880px) {
      font-size: 18px;
    }

    @media (max-width: 1600px) {
      width: 400px;
    }

    @media (max-width: 1366px) {
      width: 425px;
    }

    @media (max-width: 1024px) {
      width: 300px;
    }

    @media (max-width: 560px) {
      width: 250px;
      font-size: 14px;
      padding: 8px;
      border-radius: 6px;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: rgba($color: $black, $alpha: 0.2);
    }
  }

  &-input-group {
    margin-top: 40px;
    margin-bottom: 70px;

    @media (max-width: 1880px) {
      margin-bottom: 20px;
    }

    @media (max-width: 560px) {
      margin-top: 20px;
    }
  }
}

.text-sm {
  font-size: 18px;
  color: $blue;
  font-weight: 300;

  @media (max-width: 560px) {
    font-size: 12px;
  }
}

.input-group {
  border-radius: 10px;
  border: 0.5px solid rgba($color: $black, $alpha: 0.3);
  padding: 10px;
  background: $white;

  @media (max-width: 560px) {
    font-size: 14px;
    padding: 8px;
    border-radius: 6px;
  }

  .form-control {
    border: none;
    border-radius: 0;
    padding: 0;
    background: none;
  }

  &-text {
    background: none;
    padding: 5px;
    border: none;
  }
}

.btn-submit {
  background: $primary;
  border: 1px solid $primary;
  border-radius: 10px;
  font-size: 25px;
  font-weight: 600;
  color: $white;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  transition: all 0.3s ease;

  @media (max-width: 1880px) {
    font-size: 22px;
  }

  @media (max-width: 560px) {
    font-size: 18px;
  }

  &:hover {
    color: $secondary;
    background: none;
    border-color: $secondary;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown__button {
  background-color: $white;
  padding: 7px 20px;
  border: none;
  color: $dark;
  cursor: pointer;
  text-align: left;
  width: 200px;
  border-radius: 14px;
  font-size: 21px;
  font-weight: 800;
  display: flex;
  align-items: center;

  @media (max-width: 1440px) {
    border-radius: 10px;
    font-size: 18px;
  }

  @media (max-width: 560px) {
    font-size: 14px;
    width: 180px;
  }

  span {
    transform: rotate(0);
    transition: all 0.3s ease;

    svg {
      @media (max-width: 560px) {
        width: 20px;
        height: 22px;
      }
    }

    &.rotate {
      transform: rotate(180deg);
    }
  }
}

.dropdown__menu {
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: none;
  list-style: none;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  margin-top: 16px;
  overflow: hidden;
}

.dropdown__menu li {
  cursor: pointer;
  padding: 10px 20px;
  border-top: 1px solid $dark;
  font-size: 21px;
  font-weight: 400;
  transition: all 0.3s ease;

  @media (max-width: 560px) {
    font-size: 14px;
  }

  &.active {
    font-weight: 800;
  }

  &:first-child {
    background-image: url(../icons/RightArrow.svg);
    background-position: center right 20px;
    background-repeat: no-repeat;
    border: none;
  }
}

.dropdown__menu li:hover {
  background-color: #f0f0f0;
}

.dropdown__menu.show {
  display: block;
}

.detail-listing-wrap {
  padding: 25px 40px;
  border-radius: 30px;
  background-color: $white;
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 126px;

  @media (max-width: 1680px) {
    padding: 20px 30px;
  }

  @media (max-width: 1024px) {
    border-radius: 15px;
  }

  &:first-child {
    margin-top: 15px;
  }

  &.user-device {
    .listing-detail-wrap {
      grid-template-columns: 30% 28% 28%;
    }
  }
}

.listing-detail-wrap {
  display: grid;
  align-items: center;
  grid-template-columns: 20% 20% 30% 30%;
  width: 80%;
}

.user {
  &-name {
    font-size: 33px;
    margin-bottom: 5px;
    font-weight: 700;

    @media (max-width: 1680px) {
      font-size: 28px;
      margin-bottom: 0px;
    }

    @media (max-width: 1440px) {
      font-size: 26px;
    }

    @media (max-width: 1024px) {
      font-size: 22px;
    }
  }

  &-company {
    font-size: 15px;
  }

  &-dates,
  &-contact {
    margin-left: 45px;
  }

  &-data-text {
    font-size: 18px;
    font-weight: 800;
    text-transform: none;

    @media (max-width: 820px) {
      font-size: 16px;
    }

    &:first-child {
      margin-bottom: 15px;
    }

    span {
      color: $primary;
    }
  }
}

// .about-info-wrap {
//   margin-right: 80px;

//   @media (max-width: 1280px) {
//     margin-right: 50px;
//   }
// }

.device-info {
  padding-right: 45px;
  border-right: 0.8px solid rgba($color: $primary, $alpha: 0.7);

  @media (max-width: 560px) {
    padding-right: 0;
    border-right: none;
  }
}

.assigned {
  &-num {
    font-size: 35px;
    color: $primary;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;

    @media (max-width: 1680px) {
      font-size: 30px;
      margin-bottom: 5px;
    }

    @media (max-width: 1680px) {
      font-size: 27px;
    }

    @media (max-width: 1366px) {
      font-size: 23px;
    }

    @media (max-width: 768px) {
      font-size: 30px;
    }

    @media (max-width: 560px) {
      font-size: 23px;
    }
  }

  &-text {
    font-size: 16px;
    text-align: center;

    @media (max-width: 1440px) {
      font-size: 15px;
    }

    @media (max-width: 768px) {
      font-size: 20px;
    }

    @media (max-width: 560px) {
      font-size: 18px;
    }
  }
}

.btn-action {
  margin-left: 15px;

  @media (max-width: 560px) {
    margin-left: 5px;
  }
}

.card-actions {
  display: flex;
  align-items: center;

  svg {
    @media (max-width: 768px) {
      width: 35px;
    }
  }

  @media (max-width: 768px) {
    padding-right: 20px;
  }
}

.flow-unit {
  font-size: 11px;
  text-align: center;
}

.total-flow-info {
  margin-left: 60px;
  margin-right: 20px;

  @media (max-width: 1440px) {
    margin-left: 50px;
    margin-right: 15px;
  }

  @media (max-width: 1366px) {
    margin-left: 42px;
  }

  @media (max-width: 1280px) {
    margin-left: 30px;
  }

  @media (max-width: 768px) {
    margin-left: 20px;
  }
}

.empty-text {
  font-size: 20px;
  font-weight: 800;
  color: $primary;

  @media (max-width: 820px) {
    font-size: 16px;
  }
}

.close-icon {
  position: absolute;
  top: 35px;
  right: 33px;

  @media (max-width: 1440px) {
    top: 20px;
    right: 25px;
  }

  @media (max-width: 560px) {
    top: 10px;
    right: 15px;
  }

  svg {
    @media (max-width: 1440px) {
      width: 20px;
    }

    @media (max-width: 560px) {
      width: 15px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.leaflet-container {
  height: 400px;
}

.map-box {
  overflow: hidden;
  border-radius: 30px;
  position: -webkit-sticky;
  position: sticky;
  top: 15px;

  @media (max-width: 1680px) {
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    position: relative;
    z-index: 5;
  }
}

.user-name {
  color: $dark;
  transition: 0.3s ease;

  &:hover {
    color: $primary;
  }
}

.map-area {
  .leaflet-container {
    height: 400px !important;
  }
}

.total-page-text {
  @media (max-width: 1440px) {
    font-size: 14px;
  }

  @media (max-width: 560px) {
    font-size: 10px;
  }
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  @media (max-width: 768px) {
    height: 45vh;
  }
}

.dropdown-action-btn {
  padding: 0;
  background: none;
  border: none;

  &:hover,
  &:active,
  &.active {
    background: none;
  }

  &::after {
    content: none;
  }
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background: none;
  outline: none;
}

.btn-check:checked + .btn:focus-visible,
:not(.btn-check) + .btn:active:focus-visible,
.btn:first-child:active:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible {
  box-shadow: none;
}

.w-fit {
  width: fit-content;
}

.text-danger {
  color: #dc3545 !important;
}
