.header {
  &-wrap {
    position: absolute;
    top: 0;
    width: 100vw;
    padding: 46px 84px 40px;
    left: 0;
    z-index: 1;

    @media (max-width: 992px) {
      padding: 40px 65px 35px;
    }

    @media (max-width: 560px) {
      // flex-direction: column;
      padding: 20px 50px 25px;
    }

    &.scrolled {
      background-color: rgba($color: $white, $alpha: 0.8);
    }
  }
}

.right-menu-wrap {
  @media (max-width: 560px) {
    // margin-top: 25px;
  }
}

.logo {
  height: 60px;

  @media (max-width: 1800px) {
    height: 45px;
  }

  @media (max-width: 560px) {
    height: 22px;
  }
}

.search {
  &bar-wrap {
    width: 70px;
    height: 70px;
    background: $white;
    border-radius: 20px;
    margin-right: 50px;
    justify-content: center;
    transition: all 0.3s ease;

    @media (max-width: 1800px) {
      width: 60px;
      height: 60px;
      border-radius: 15px;
      margin-right: 35px;
    }

    @media (max-width: 560px) {
      width: 35px;
      height: 35px;
      border-radius: 5px;
      margin-right: 10px;
    }

    &:hover,
    &.active {
      width: 420px;
      padding-left: 27px;
      padding-right: 10px;

      @media (max-width: 1800px) {
        padding-left: 20px;
      }

      @media (max-width: 1024px) {
        width: 260px;
      }

      .search-area {
        display: block;
      }
    }
  }

  &-area {
    border: none;
    background: none;
    font-size: 25px;
    color: $black;
    border-radius: 0;
    width: 95%;
    display: none;

    @media (max-width: 1800px) {
      font-size: 23px;
    }

    @media (max-width: 1024px) {
      font-size: 17px;
    }

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: $black;
    }
  }

  &-icon {
    svg {
      @media (max-width: 1800px) {
        width: 35px;
      }

      @media (max-width: 560px) {
        width: 15px;
      }
    }
  }
}

.notification-tab {
  width: 69px;
  height: 69px;
  border-radius: 20px;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 66px;
  cursor: pointer;
  position: relative;

  svg {
    @media (max-width: 1800px) {
      width: 30px;
    }
  }

  @media (max-width: 1800px) {
    width: 60px;
    height: 60px;
    border-radius: 15px;
    margin-right: 35px;
  }

  @media (max-width: 560px) {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    margin-right: 10px;
  }

  &.active {
    &::after {
      content: "";
      position: absolute;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #dc3545;
      top: 20%;
      right: 20%;
    }

    svg {
      animation: shake 0.5s ease-in-out infinite;
    }
  }
}

.my-account {
  padding: 12px 42px;
  border-radius: 20px;
  background: $white;
  cursor: pointer;

  @media (max-width: 1800px) {
    padding: 11px 35px;
    border-radius: 15px;
  }

  @media (max-width: 560px) {
    padding: 8px 20px;
    border-radius: 5px;
  }

  &:hover {
    p {
      color: $secondary;
    }
  }

  p {
    font-size: 30px;
    font-weight: 300;
    color: $primary;
    transition: all 0.3s ease;

    @media (max-width: 1800px) {
      font-size: 26px;
    }

    @media (max-width: 560px) {
      font-size: 12px;
      font-weight: 800;
    }
  }
}

.searched {
  &-data {
    position: absolute;
    top: 85px;
    min-width: 420px;
    left: 0;
    padding: 5px 0;
    background: $white;
    border-radius: 10px;
    display: grid;

    .empty-msg {
      text-align: center;
      font-size: 22px;
      color: $danger;
      padding: 15px;
    }
  }

  &-device {
    font-size: 22px;
    font-weight: 400;  // changed from 700 to 400
    padding-block: 10px;
    transition: all 0.3s ease;
    padding-inline: 20px;
    min-width: 100%;

    .matched-text {
      font-weight: 700;
    }

    &:hover {
      background: $gray-100;
    }
  }
}

.notification {
  &-data {
    @media (max-width: 560px) {
      left: -430%;
      top: 40px;
    }
    left: -20%;
    position: absolute;
    top: 80px;
    width: 350px;
    padding: 10px 15px;
    border-radius: 10px;
    background: $white;
    box-shadow: 10px 10px 21px 0px rgba(0, 0, 0, 0.12);
    z-index: 99;

    .empty-msg {
      font-size: 18px;
      color: $danger;
      font-weight: 500;
    }

    &-msg {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

.notification-bell {
  @media (max-width: 560px) {
    height: 15px;
  }
}

.searchbar-box-wrap {
  position: relative;

  &.active {
    @media (max-width: 560px) {
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      background: rgba($color: $dark, $alpha: 0.4);
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: 100px;
    }
  }
}

.notification-msg {
  padding-block: 7px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: rgba($color: $dark, $alpha: 0.4);
  }

  &:last-child {
    &::before {
      content: none;
    }
  }
}

.my-account {
  position: relative;
}

.dropdown {
  &-wrap-box {
    display: none;
    position: absolute;
    top: 115%;
    left: 0;
    width: 100%;
    padding: 15px;
    background: $white;
    border-radius: 15px;

    &.show {
      display: block;
    }
  }

  &-menu-text {
    font-size: 20px;
    color: $dark;
    margin-block: 5px;
  }
}
