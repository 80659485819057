.greeting-text {
  margin-bottom: 20px;

  p {
    color: $primary;
    font-size: 29px;
    font-weight: 400;

    @media (max-width: 1600px) {
      font-size: 27px;
    }

    @media (max-width: 560px) {
      font-size: 18px;
    }

    &.time {
      font-size: 30px;

      @media (max-width: 1600px) {
        font-size: 27px;
      }

      @media (max-width: 560px) {
        font-size: 16px;
      }
    }
  }
}

.title {
  font-size: 29px;
  color: $black;
  font-weight: 700;

  @media (max-width: 560px) {
    font-size: 18px;
  }
}

.current-flow-info {
  margin-inline: 30px;

  @media (max-width: 1440px) {
    margin-inline: 12px;
  }
}

.fluid {
  &-wrap {
    padding: 20px 10px 20px 10px;
    background: $white;
    border-radius: 20px;
    height: 123px;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   z-index: 0;
    //   top: 0;
    //   left: 0;
    // background-image: url("../../img/bubble-bg.svg");
    background-position: bottom center;
    background-repeat: repeat;
    background-size: cover;
    //   width: 100%;
    //   height: 100%;
    // }

    @media (max-width: 1440px) {
      padding: 20px 10px 20px 10px;
      height: auto;
    }

    @media (max-width: 768px) {
      display: grid;
      grid-template-areas:
        "item1 item3"
        "item2 item2";
      grid-template-rows: auto 1fr;
      grid-template-columns: 1fr auto;
      gap: 10px;
      height: auto;
      border-radius: 15px;
    }

    @media (max-width: 560px) {
      padding: 0px 0 20px 25px;
    }
  }

  &-name {
    font-size: 33px;
    font-weight: 500;
    margin-bottom: 10px;
    color: $black;

    @media (max-width: 1800px) {
      font-size: 31px;
    }

    @media (max-width: 1280px) {
      font-size: 28px;
    }
  }

  &-status {
    svg {
      @media (max-width: 1440px) {
        width: 40px;
      }

      @media (max-width: 560px) {
        width: 25px;
      }
    }
  }
}

.item1 {
  @media (max-width: 768px) {
    grid-area: item1;
  }

  @media (max-width: 560px) {
    margin-top: 20px;
  }
}

.item2 {
  @media (max-width: 768px) {
    grid-area: item2;
    align-self: center;
    flex-wrap: wrap;
    padding-right: 35px;
    padding-top: 25px;
    justify-content: space-evenly;
  }

  @media (max-width: 560px) {
    justify-content: center;
    row-gap: 20px;
  }
}

.item3 {
  @media (max-width: 768px) {
    grid-area: item3;
    justify-self: end;
    align-self: baseline;
  }
}

.last-updated {
  @media (max-width: 768px) {
    margin-top: 15px;
  }
}

.user-detail {
  display: grid;
  grid-template-columns: 70% 25.5%;
  column-gap: 63px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }
}

.pin-to-top {
  svg {
    @media (max-width: 560px) {
      width: 25px;
    }
  }
}

.subscription-detail {
  font-size: 15px;
  color: $black;

  @media (max-width: 1280px) {
    font-size: 13px;
  }
}

.flow {
  &-num {
    font-size: 35px;
    font-weight: 700;
    color: $primary;
    text-align: center;
    margin-bottom: 0;

    @media (max-width: 1800px) {
      font-size: 33px;
    }

    @media (max-width: 1680px) {
      font-size: 30px;
    }

    @media (max-width: 1440px) {
      font-size: 28px;
    }

    @media (max-width: 560px) {
      font-size: 25px;
    }
  }

  &-type {
    font-size: 16px;
    color: $black;
    text-align: center;
  }
}

.unit {
  font-size: 13px;
  color: $black;
  text-align: center;
}

.tank-container {
  padding: 26px;
  border-radius: 28px;
  background-color: $white;
  height: 403px;
  width: 100%;
  margin-top: 15px;
  background-image: url("../../img/flow.gif");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100% 50%;
}

.device-card-flow {
  padding: 26px;
  border-radius: 28px;
  background-color: $white;
  height: 403px;
  width: 100%;
  margin-top: 15px;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100% 27%;
}

.device-card-level {
  padding: 26px;
  border-radius: 28px;
  background-color: $white;
  height: 403px;
  width: 100%;
  margin-top: 15px;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 60%;
}

.container-head {
  margin-bottom: 60px;
}

.pinned {
  svg {
    fill: rgba($color: $primary, $alpha: 0.5);
  }
}
